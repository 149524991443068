var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kiwi-filebuffer-outer-container" }, [
    !_vm.fileList.length
      ? _c("div", [
          _vm._v("\n        No files have recently been uploaded...\n    ")
        ])
      : _c(
          "div",
          { staticClass: "kiwi-filebuffer-inner-container" },
          _vm._l(_vm.fileList.slice().reverse(), function(file, idx) {
            return _c(
              "div",
              {
                key: file.url,
                staticClass: "kiwi-filebuffer-download-container"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "kiwi-filebuffer-anchor",
                    attrs: {
                      href: file.url,
                      download: "",
                      title: "Download File",
                      target: "_blank"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-download kiwi-filebuffer-downloadicon"
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "kiwi-filebuffer-filetitle",
                    staticStyle: { "font-size": "18px" }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "kiwi-filebuffer-anchor",
                        attrs: { href: file.url, title: "Preview File" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.loadContent(file.url)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(file.fileName) +
                            "\n                "
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "kiwi-filebuffer-fileinfo" }, [
                  _vm._v(" " + _vm._s(file.nick))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "kiwi-filebuffer-fileinfo" }, [
                  _vm._v(" " + _vm._s(file.time))
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { clear: "both" } })
              ]
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }